import { Component, Vue, Ref } from "vue-property-decorator";
import { isEmpty, checkPassword } from "@helpers";
import { VForm } from "@/types/vuetify";
import { profileModule } from "@store/namespaces";
import { ProfileActions, ProfileGetters } from "@store/modules/profile/types";
import { SecondFactorCode } from "@/types/2fa";
import { AutotestId } from "@/types/autotests";

@Component
export default class UpdatePassword extends Vue {
  @profileModule.Action("updateProfilePassword")
  private readonly updateProfilePasswordAction!: ProfileActions["updateProfilePassword"];
  @profileModule.Getter
  private readonly profileSecondFactorEnabled!: ProfileGetters["profileSecondFactorEnabled"];

  @Ref("form") private readonly formRef!: VForm;

  private oldPassword = "";
  private newPassword = "";
  private repeatNewPassword = "";
  private secondFactorCode: SecondFactorCode = "";
  private loading = false;

  private get autoTestId() {
    return {
      saveButton: AutotestId.CHANGE_PASSWORD_SAVE_BUTTON,
      cancelButton: AutotestId.CHANGE_PASSWORD_CANCEL_BUTTON,
      closeButton: AutotestId.CHANGE_PASSWORD_CLOSE_BUTTON,
      secondFactorCodeTextField:
        AutotestId.CHANGE_PASSWORD_SECOND_FACTOR_CODE_TEXT_FIELD,
      currentPasswordTextField:
        AutotestId.CHANGE_PASSWORD_CURRENT_PASSWORD_TEXT_FIELD,
      repeatPasswordTextField:
        AutotestId.CHANGE_PASSWORD_REPEAT_PASSWORD_TEXT_FIELD,
    };
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      password: (v: string) =>
        checkPassword(v) ||
        this.$vuetify.lang.t("$vuetify.errors.password.length"),
      mustMatchPassword: (v: string) =>
        this.newPassword === v ||
        this.$vuetify.lang.t("$vuetify.errors.password.must_match"),
    };
  }

  private async onSubmitForm() {
    if (this.loading || !this.formRef.validate()) return;

    this.loading = true;

    try {
      await this.updateProfilePasswordAction({
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
        secondFactorCode: this.secondFactorCode,
      });

      this.$notify({
        type: "success",
        title: this.$vuetify.lang.t("$vuetify.info.password_changed"),
      });
      this.$emit("success");
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  private onClose() {
    this.$emit("close");
  }
}
